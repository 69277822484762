import { graphql, Link } from "gatsby";
import React from "react";
import Layout from "~components/layouts/Layout";
import LandingpagePersonalHeader from "~components/sections/landingpages/LandingpagePersonalHeader";
import SeoText from "~components/sections/landingpages/SeoText";

export default function Page({ data, location }) {
	const keyword = "JavaScript Entwickler";
	const title = "JavaScript Entwickler ▷ Freelancer zum Fixpreis";
	const description = "Suchst Du einen zuverlässigen JavaScript Entwickler? Wir helfen Dir gerne bei Deinem Projekt!";

	return (
		<Layout
			location={location}
			title={title}
			desc={description}
			imageFacebook={`${data.site.siteMetadata.siteUrl}${data.seoImage.facebook.gatsbyImageData.images.fallback.src}`}
			imageFacebookWidth={data.seoImage.facebook.gatsbyImageData.width}
			imageFacebookHeight={data.seoImage.facebook.gatsbyImageData.height}
			imageTwitter={`${data.site.siteMetadata.siteUrl}${data.seoImage.twitterAndProduct.gatsbyImageData.images.fallback.src}`}
		>
			<LandingpagePersonalHeader
				title={title}
				description={description}
				keyword={keyword}
				schemaImage={`${data.site.siteMetadata.siteUrl}${data.seoImage.twitterAndProduct.gatsbyImageData.images.fallback.src}`}
				price="90"
				experienceYears="6"
				skills={["JavaScript", "Node", "Express", "React"]}
				siteUrl={data.site.siteMetadata.siteUrl}
				path={location.pathname}
			/>

			<SeoText
				text={[
					{
						headline: "Was macht ein JavaScript Entwickler?",
						headlineAs: "h2",
						text: [
							"Suchst Du einen Entwickler, der Dir dynamische und interaktive Websites baut? Dann kommst Du an einem JavaScript-Entwickler nicht vorbei. Jede einzelne Seite, die Du da draußen findest, wird standardmäßig über JavaScript dynamisch und interaktiv. HTML und CSS dienen für die Inhalte und das Styling einer Website und sind allein ausreichend für eine einfache und statische Website. JavaScript hingegen ist aber das Salz in der Suppe und macht eine Website dynamisch. Von beweglichen Elementen im Frontend, als auch zur Datenverarbeitung und Kommunikation mit anderen Schnittstellen, kann JavaScript verwendet werden und ist somit das Schweizer Taschenmesser für jeden Webentwickler.",
						],
					},
					{
						headline: "Der Nutzen für Dein Business",
						headlineAs: "h2",
						text: [
							"Ein JavaScript Entwickler ist ein Alleskönner. In den allermeisten Fällen wird ein JavaScript benutzt, um eine einfache Standardwebsite zu entwickeln und diese in Teilbereichen dynamisch zu machen. Aber auch bei komplexen Backend-Systemen, die im Web über ein Interface bedienbar sein müssen, ist JavaScript die richtige Entscheidung. Zudem ist JavaScript als Runtime Environment abseits des Browsers auf jedem PC oder Server ausführbar und ermöglicht somit auch, eigene Server-Logiken, Desktopanwendungen oder Mobile Apps zu entwickeln.",
						],
					},
					{
						headline: "Wir als JavaScript Entwickler",
						headlineAs: "h2",
						text: [
							<>
								JavaScript ist unser alltägliches Brot und eine Standard-Programmiersprache in unserem Repertoire. Wir
								können damit ganzheitliche Lösungen anbieten, da JavaScript uns ermöglicht, als{" "}
								<Link to="/leistungen/fullstack-entwickler/" className="underline">
									Fullstack Entwickler
								</Link>{" "}
								zu agieren. Suchst Du also den richtigen Partner für Deine Website, für die Digitalisierung Deines
								Unternehmens oder für Deine nächste Software-Idee, dann bist Du bei uns genau richtig. Wir können Dir
								dank JavaScript eine ganzheitliche Lösung in allen Bereichen anbieten.
							</>,
						],
					},
					{
						headline: "Kosten und Gehalt",
						headlineAs: "h2",
						text: [
							"Das Gehalt eines angestellten JavaScript Entwicklers kann sich schnell zwischen 42.000 € und €70.000 im Jahr belaufen. Wie kommt es zu einem so hohen Gehalt? JavaScript ist eine Allzweckwaffe für die fortlaufende Digitalisierung im Web. Daher ergibt sich eine große Nachfrage nach JavaScript Jobs und der Fachkräfte-Mangel wird immer größer, da die Hürde zum Programmieren täglich größer werden. Der JavaScript Entwickler ist nicht nur Experte im Entwickeln, sondern von ihm wird auch noch Wissen in Design, Serverarchitektur, Projektmanagement und vieles mehr abverlangt.",
							"Um Kosten besser kalkulieren zu können, können gerade kleine und mittelständische Unternehmen auf einen Freelancer zurückgreifen, der Ihnen eine individuelle Entwicklung anbieten können und die Lösung als kalkulierbares Angebot unterbreiten. Frage uns also gerne an, wenn Du eine Lösung für Dein Business sucht, ohne einen teuren Entwickler anstellen zu müssen.",
						],
					},
					{
						headline: "JavaScript Frameworks und Bibliotheken",
						headlineAs: "h2",
						text: [
							<>
								Viele Technologien basieren auf JavaScript und daher ist es nicht verwunderlich, dass viele Unternehmen
								gar nicht nach einem JavaScript Entwickler suchen, sondern direkt einen Experten für bestimmte
								Frameworks oder Bibliotheken suchen. Die bekanntesten Bibliotheken und Frameworks sind{" "}
								<Link to="/leistungen/react-entwickler/" className="underline">
									React
								</Link>
								, Vue, Angular, Svetle für das Frontend und Express, Fastify für Node, also dem Backend. Suchen
								Unternehmen einen Fachmann in mit Kenntnissen in diesen Technologien, so suchen diese insgeheim
								eigentlich einen Experten für JavaScript. Ein guter Entwickler macht sich aber nicht von einzelnen
								Frameworks und Bibliotheken abhängig und kann diese schnell erlernen, da er die Programmiersprache
								beherrscht und diese Technologien meist nur eine Abstraktion sind, die häufig wiederholende Lösungen in
								einfachen kompakten Code verpacken.
							</>,
						],
					},
				]}
			/>
		</Layout>
	);
}

export const query = graphql`
	{
		seoImage: file(relativePath: { eq: "pages/leistungen/javascript-entwickler/javascript-entwickler_seo-image.jpg" }) {
			facebook: childImageSharp {
				gatsbyImageData(height: 630, width: 1200, formats: JPG, quality: 100)
			}
			twitterAndProduct: childImageSharp {
				gatsbyImageData(aspectRatio: 1, formats: JPG, quality: 100)
			}
		}

		site {
			siteMetadata {
				siteUrl
			}
		}
	}
`;
